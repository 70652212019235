import React, { useState } from 'react';
import styled from 'styled-components';
import Wallet from './WalletComponent';
import ProfileComponent from './ProfileComponent';
import { FaRegUserCircle, FaWallet } from "react-icons/fa";
import Select from 'react-select';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

const Sidebar = styled.div`
    width: 200px;
    height: 100%; /* Make Sidebar take up 100% height of its parent */
    background-color: #00171a;
    gap: 8px;
    padding: 16px;
    overflow-y: auto; /* Make the sidebar content scrollable */
  
    @media (max-width: 940px) {
        display: none;
    }
`;

const SidebarList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    .full-unstyled-link {
        display: flex;
        align-items: center;
        padding: 10px;
        text-decoration: none;
        color: white;
        cursor: pointer;
        &:hover {
            background-color: #6b8a99;
        }
    }

    .active {
        background-color: #6b8a99;
    }

    svg {
        margin-right: 10px;
    }
`;

const ContentArea = styled.div`
    flex: 1;
    padding: 10px;
`;

const Dropdown = styled.div`
    display: none;
    margin-bottom: 10px;
    @media (max-width: 940px) {
        display: block;
    }
`;

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#00171a',
        color: 'white',
        border: state.isFocused ? '1px solid #6b8a99' : '1px solid #00171a',
        boxShadow: state.isFocused ? '0 0 0 1px #6b8a99' : 'none',
        '&:hover': {
            border: '1px solid #6b8a99',
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: '#00171a',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'white',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: state.isSelected ? '#6b8a99' : state.isFocused ? '#334e57' : '#00171a',
        color: 'white',
        '&:hover': {
            backgroundColor: '#6b8a99',
        },
    }),
    input: (provided: any) => ({
        ...provided,
        color: 'white',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        color: 'white',
    }),
};

const options = [
    { value: 'profile', label: 'Profile', icon: <FaRegUserCircle /> },
    { value: 'transactions', label: 'Transactions', icon: <FaWallet /> }
];

const IndicatorSeparator = () => {
    return null; // This renders nothing, effectively removing the separator
};

const ProfileDetailsLayoutComponent: React.FC = () => {
    const [selectedComponent, setSelectedComponent] = useState<string>('profile');

    const handleSelectionChange = (selectedOption: any) => {
        setSelectedComponent(selectedOption.value);
    };

    const formatOptionLabel = (option: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {option.icon}
            <span style={{ marginLeft: 10 }}>{option.label}</span>
        </div>
    );

    return (
        <Container>
            <Sidebar>
                <SidebarList>
                    <li>
                        <a
                            className={`full-unstyled-link ${selectedComponent === 'profile' ? 'active' : ''}`}
                            onClick={() => setSelectedComponent('profile')}
                            title="Profile"
                        >
                            <FaRegUserCircle />
                            <span>Profile</span>
                        </a>
                    </li>
                    <li>
                        <a
                            className={`full-unstyled-link ${selectedComponent === 'transaction' ? 'active' : ''}`}
                            onClick={() => setSelectedComponent('transactions')}
                            title="View my orders"
                        >
                            <FaWallet />
                            <span>Transactions</span>
                        </a>
                    </li>
                </SidebarList>
            </Sidebar>
            <ContentArea>
                <Dropdown>
                    <Select
                        value={options.find(option => option.value === selectedComponent)}
                        onChange={handleSelectionChange}
                        options={options}
                        styles={customStyles}
                        formatOptionLabel={formatOptionLabel}
                        components={{ IndicatorSeparator }} // Use the custom IndicatorSeparator component
                    />
                </Dropdown>
                {selectedComponent === 'profile' && <ProfileComponent />}
                {selectedComponent === 'transactions' && <Wallet />}
            </ContentArea>
        </Container>
    );
};

export default ProfileDetailsLayoutComponent;
