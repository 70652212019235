class Feed {
    image1: string;
    image2: string;
    price: string;
  
    constructor(image1: string, image2: string, price: string) {
      this.image1 = image1;
      this.image2 = image2;
      this.price = price;
    }
  }
  
  export default Feed;