// src/auth/config.ts

import configJson from "./auth_config.json";
import AuthConfig from "./authConfig";

export function getConfig(): AuthConfig {
  // Configure the audience here.
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
  };
}
