// src/components/BoxesPage.tsx
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import DynamicBoxes from "./DynamicBoxes";
import { BoxType } from "../utils/boxTypes";
import BoxesPageHeading from "./BoxesPageHeading";
import Footer from "./Footer";
import localization from "../services/localization";
import HeroComponent from "./HeroComponent";

const BoxesPageWrapper = styled.div`
  padding: 20px;
  color: #fff;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

const BoxesPage: React.FC = () => {
  const [boxCount, setBoxCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setBoxCount(2);
      } else if (windowWidth < 768) {
        setBoxCount(3);
      } else if (windowWidth < 1024) {
        setBoxCount(4);
      } else {
        setBoxCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  return (
    <BoxesPageWrapper ref={contentRef}>
      <HeroComponent/>
      <BoxesPageHeading $gradientColors={["#ffd400 0%", "#0af 100%"]}>
      {localization.featured_boxes}
      </BoxesPageHeading>
      <DynamicBoxes boxCount={boxCount} boxType={BoxType.Featured} />

      <BoxesPageHeading $gradientColors={["#f00 0%", "#ff0 100%"]}>
      {localization.hot_boxes}
      </BoxesPageHeading>
      <DynamicBoxes boxCount={boxCount} boxType={BoxType.Hot} />

      <BoxesPageHeading $gradientColors={["#0af 0%", "#ff0 100%"]}>
      {localization.new_boxes}
      </BoxesPageHeading>
      <DynamicBoxes boxCount={boxCount} boxType={BoxType.New} />
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </BoxesPageWrapper>
  );
};

export default BoxesPage;
