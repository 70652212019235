import React, { createContext, useContext, useState } from 'react';

export enum ModalType {
  SIGN_UP = 'sign_up',
  SIGN_IN = 'sign_in',
  FORGOT_PASSWORD = 'forgot_password',
  // Add more modal types as needed
}

interface ModalContextProps {
  openModal: (modalType: ModalType) => void;
  closeModal: () => void;
  currentModal: ModalType | null;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);

  const openModal = (modalType: ModalType) => {
    setCurrentModal(modalType);
  };

  const closeModal = () => {
    setCurrentModal(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, currentModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
