import axios from "axios";
import baseURL from "./apiConfig";

const initializeApp = () => {
  // Setting base URL for all API request via axios
  axios.defaults.baseURL = baseURL;

  //Enable it for removing console.log from prod
  //console.log = () => {};
};

export default initializeApp;
