// src/components/Layout.tsx
import React from 'react';
import Header from './Header';
import ContentArea from './ContentArea';
import Sidebar from './Sidebar';
import styled from 'styled-components';

const LayoutWrapper = styled.div`
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  height: 100vh; /* Set height to 100% of the viewport height */
  overflow: hidden; /* Prevent unnecessary scrollbars */
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%; /* Make ContentWrapper take up 100% height of its parent */

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ResponsiveContentArea = styled(ContentArea)`
  flex: 1;
  overflow-y: auto; /* Enable scrolling if content overflows */
`;

const ResponsiveSidebar = styled(Sidebar)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Layout: React.FC = () => {
  return (
    <LayoutWrapper>
      <Header />
      <ContentWrapper>
        <ResponsiveContentArea />
        <ResponsiveSidebar />
      </ContentWrapper>
    </LayoutWrapper>
  );
};

export default Layout;
