// src/components/Wallet.tsx
import React from 'react';
import TransactionComponent from './TransactionComponent';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input'; // Import react-phone-number-input component
import 'react-phone-number-input/style.css'; // Import styles for react-phone-number-input
import localization from '../services/localization';

const ScrollableContainer = styled.div`
  height: 100vh; /* Set the height of the container to full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const UserHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgContainer = styled.div`
  cursor: pointer;
`;

const UserIcon = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: 472px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FullWidthElement = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;

  width: 100%;
`;

const FullWidthElementCenter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const UploadBtnWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
`;

const Button = styled.button`
  border: 1px solid #ccc;
  color: white;
  background-color: #4caf50;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const Input = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

const TextWhite = styled.p`
  color: white;
  margin: 0 10px;
`;

const UserDetailsContainer = styled.div`
  text-align: center;
`;

const Username = styled.p`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UsernameText = styled.span`
  display: inline-block;
  width: 130px;
`;

const PencilIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

const Usermail = styled.p`
  color: white;
`;

const EmailText = styled.span`
  display: inline-block;
`;

const SectionTitleSpan = styled.span`
  width: 174px;
  height: 17px;
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d9ffff;
`;

const PersonalInformationContainer = styled.div`
  width: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const SectionHeading = styled.div`
  width: 100%;
  height: 32px;
  flex-grow: 0;
  padding: 8px 0;
  border-bottom: solid 1px #acbfbf;
`;

const PersonalInformationLabel = styled.label`
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  color: #d9ffff;
`;

const PersonalInformationInput = styled.input`
  width: 100%; /* Adjusting width to full */
  height: 24px;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const DropDownSelect = styled.select`
  width: 100%; /* Adjusting width to full */
  height: 32px;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%; /* Adjusting width to full */
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const RightMargin = styled.div`
  margin-right: 16px;
  width: 100%;

  @media (max-width: 472px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const WithoutMargin = styled.div`
  width: 100%;

  @media (max-width: 472px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

const QRCodeRowContainer = styled.div`
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  gap: 16px; /* Adjust the gap as needed */
  width: 100%; /* Ensure it takes the full width of its container */

  @media (max-width: 472px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NoticeText = styled.div`
  flex: 1;
  height: 42px;
  font-family: Verdana;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #cfe5e5;

  .text-style-1 {
    font-weight: bold;
    color: #80ffff;
  }
`;

const QRCodeBox = styled.div`
  flex: 1;
  flex-basis: 50%; /* Ensure equal width */
  flex-shrink: 0;
  max-width: 50%;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButton = styled.button`
    width: 156px;
height: 24px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8px;

  
  font-family: Verdana;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ccfffb;



  box-shadow: inset 4px 8px 12px 0 rgba(255, 255, 255, 0.5),
    inset -2px -6px 10px 0 rgba(0, 0, 0, 0.6);
  background-color: #00bbcc;
  cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;


const Wallet: React.FC = () => {
  return <div>
    <TransactionComponent />
  </div>;
};

export default Wallet;
