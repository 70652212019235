import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import {
  favouriteBoxesList,
  fetchFavouriteBoxes,
  isLoadingFavouriteBoxes,
  isNeedToRefreshFavorites,
  setCurrentPage,
} from "../features/chaosSlice";
import { formatNumber, getImageSource } from "../utils/utility";
import {
  SquareGrid,
  SquareBox,
  BoxContent,
  BoxImage,
  BoxTitle,
  PriceTag,
} from "./DynamicBoxes";
import { styled } from "styled-components";
import likeIcon from "../assets/like_icon.svg";
import { setBoxDetails } from "../features/boxDetailsSlice";
import { useNavigate } from "react-router-dom";
import localization from "../services/localization";

const LoadingMessage = styled.p`
  padding: 50px;
  text-align: center;
  color: #40a5c5;
`;

const FavTag = styled.img`
  object-fit: cover;
  position: absolute;
  height: 10%;
  width: auto;
  top: 9%;
  right: 7%;
`;

export const FavouriteBoxes: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const [hoveredBox, setHoveredBox] = useState<string | null>(null);
  const favouriteBoxes = useSelector(favouriteBoxesList);
  const isLoadingFavBoxes = useSelector(isLoadingFavouriteBoxes);
  const isNeedToRefresh = useSelector(isNeedToRefreshFavorites);

  const [boxCount, setBoxCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchFavouriteBoxes());
  }, []);

  useEffect(() => {
    dispatch(fetchFavouriteBoxes());
  }, [isNeedToRefresh]);

  useEffect(() => {
    // alert("favourite Boxes length => " + favouriteBoxes.length);
  }, [favouriteBoxes]);

  const currentPage = useSelector(
    (state: RootState) => state.chaosBoxes.currentPage
  );
  const totalItems = useSelector(
    (state: RootState) => state.chaosBoxes.totalItems
  );
  const boxesPerPage = useSelector(
    (state: RootState) => state.chaosBoxes.boxesPerPage
  );

  // Ref for the last box element
  const lastBoxRef = useRef<HTMLDivElement>(null);

  // Intersection Observer to trigger fetchMoreData when the last box is in view
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting && !isLoadingFavBoxes) {
        const pages = Math.ceil(totalItems / boxesPerPage);
        if (currentPage < pages) {
          dispatch(setCurrentPage(currentPage + 1)); // Action to fetch more data
          dispatch(fetchFavouriteBoxes());
        }
      }
    },
    { threshold: 0.1 }
  );

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setBoxCount(2);
      } else if (windowWidth < 768) {
        setBoxCount(3);
      } else if (windowWidth < 1024) {
        setBoxCount(4);
      } else {
        setBoxCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  useEffect(() => {
    if (lastBoxRef.current) {
      observer.observe(lastBoxRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (lastBoxRef.current) {
        observer.unobserve(lastBoxRef.current);
      }
    };
  }, [observer, isLoadingFavBoxes]);

  //const navigate = useNavigate();
  const handleBoxClick = (boxId: string, name: string) => {
    dispatch(setBoxDetails(boxId));
    // navigate(`/chaos/${name.replace(/\s/g, "-")}`);
    navigate(`/chaos/${boxId}`);

  };

  function favBoxes() {
    // if (isLoadingFavBoxes) {
    //   return <LoadingMessage>Loading...</LoadingMessage>;
    // } else

    if (favouriteBoxes.length === 0) {
      return <LoadingMessage>{localization.no_favorite_boxes}</LoadingMessage>;
    } else {
      return (
        <SquareGrid columns={boxCount}>
          {favouriteBoxes.map((box, index) => {
            if (index === favouriteBoxes.length - 1) {
              return (
                <div key={index} ref={lastBoxRef}>
                  <SquareBox
                    onMouseEnter={() => setHoveredBox(box.id)}
                    onMouseLeave={() => setHoveredBox(null)}
                    onClick={() => handleBoxClick(box.id, box.name)}
                  >
                    <BoxContent>
                      <BoxImage
                        src={getImageSource(box.picture)}
                        $isHovered={hoveredBox === box.id}
                      />
                      <BoxTitle>{box.name}</BoxTitle>
                      <PriceTag>${formatNumber(box.value)}</PriceTag>
                    </BoxContent>
                    <FavTag src={likeIcon} alt={localization.heart} />
                  </SquareBox>
                </div>
              );
            } else {
              return (
                <SquareBox
                  key={index}
                  onMouseEnter={() => setHoveredBox(box.id)}
                  onMouseLeave={() => setHoveredBox(null)}
                  onClick={() => handleBoxClick(box.id, box.name)}
                >
                  <BoxContent>
                    <BoxImage
                      src={getImageSource(box.picture)}
                      $isHovered={hoveredBox === box.id}
                    />
                    <BoxTitle>{box.name}</BoxTitle>
                    <PriceTag>${formatNumber(box.value)}</PriceTag>
                  </BoxContent>
                  <FavTag src={likeIcon} alt={localization.heart} />
                </SquareBox>
              );
            }
          })}
        </SquareGrid>
      );
    }
  }

  return <>{favBoxes()} </>;
};
