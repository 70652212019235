import React, { useEffect, useState } from "react";
import styled from "styled-components";
import deafult_box from "../assets/default_box.png";
import { AppColors } from "../theme/colors";
import { Box } from "../utils/boxTypes";
import { AppDispatch } from "../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  chaosBoxesList,
  fetchChaosBoxes,
  isLoadingChaosBoxes,
  postBoxLikeDislike,
} from "../features/chaosSlice";
import { IMAGE_BASE_URL, IMAGE_ENDPOINT } from "../api/apiConfig";
import localization from "../services/localization";
import { setBoxDetails } from "../features/boxDetailsSlice";
import { useNavigate } from "react-router-dom";
import { FavouriteBoxes } from "./FavouriteBoxes";
import { BoxItems } from "./BoxItems";
import likeIcon from "../assets/like_icon.svg";
import dislikeIcon from "../assets/dislike_icon.svg";
import Footer from "./Footer";

const PageLayout = styled.div``;
const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const BoxCarousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${AppColors.almostBlack};
`;

const CarouselRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${AppColors.almostBlack};
`;

const BoxDiv = styled.div`
  flex-grow: 4;
`;
const BoxInCarousel = styled.img`
  margin: auto;
  display: block;
  height: 240px;
  width: 240px;
  padding: 16px;
  object-fit: contain;
`;

const ActionButton = styled.button`
  width: 142px;
  height: 40px;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  margin-left: auto; /* Align to the right */
  border-radius: 8px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  font-family: Verdana;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  box-shadow: inset 4px 8px 12px 0 rgba(255, 255, 255, 0.5), inset -2px -6px 10px 0 rgba(0, 0, 0, 0.6);
  background-color: #0bc;
    cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const BoxItemsLabel = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  height: 32px;
  font-family: Verdana;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d9fcff;
`;

const BoxDetailsWrapper = styled.div`
  background-color: #0f2733;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BoxNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
`;

const BoxNamePrice = styled.div`
  margin: 0 0 8px 12.9px;
  font-family: Verdana;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff7cc;
  padding-right: 16px;
`;

const BoxTag = styled.div`
  margin: 8px 111px 21px 12.9px;
  font-family: Verdana;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d9fcff;
`;

const BoxDescription = styled.div`
  font-family: Verdana;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
  padding: 16px;
`;

const FavLabel = styled.div`
  font-family: Verdana;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 32px 16px 16px 16px;
  color: #d9fcff;
`;

const LoadingMessage = styled.p`
  padding: 50px;
  text-align: center;
  color: #40a5c5;
`;

const BaseStyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  border: 2px solid transparent;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0.375rem;
  outline: none;
  transition: color 0.3s, border-color 0.3s;
  width: 100px;
  margin: 8px;

  &:disabled {
    border-color: transparent;
    color: white;
    background-color: #383838;
  }
`;

const LikeButton = styled.img`
  ${BaseStyledButton} {
    background-color: transparent;
    color: inherit;
    border-color: transparent;

    &:hover {
      color: inherit;
      border-color: transparent;
    }
  }

  margin: auto;
  display: block;
  object-fit: cover;
`;

const ChaosPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const chaosBoxes = useSelector(chaosBoxesList);
  const isLoadingBoxes = useSelector(isLoadingChaosBoxes);

  const [selectedBoxIndex, setSelectedBoxIndex] = useState<number>(0); //selected index of chaos boxes list
  const [selectedChaosBox, setSelectedChaosBox] = useState<Box | undefined>(
    undefined
  );
  const [chaosBoxPicture, setChaosBoxPicture] = useState<string>(deafult_box);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchChaosBoxes());
  }, []);

  useEffect(() => {
    if (chaosBoxes.length > 0) {
      setSelectedChaosBox(chaosBoxes[0]);
      setChaosBoxImage(chaosBoxes[0].picture);
    }
  }, [chaosBoxes]);

  function handleLikeDislike(isLiked: boolean) {
    dispatch(
      postBoxLikeDislike({
        boxId: chaosBoxes[selectedBoxIndex].id,
        isLiked: isLiked,
      })
    );
  }

  function setChaosBoxImage(pic: string) {
    if (pic) {
      setChaosBoxPicture(`${IMAGE_BASE_URL}${IMAGE_ENDPOINT}${pic}`);
    } else {
      setChaosBoxPicture(deafult_box);
    }
  }

  const openBoxAction = () => {
    if (selectedChaosBox) {
      dispatch(setBoxDetails(selectedChaosBox.id));
      navigate(`/chaos/${selectedChaosBox.name.replace(/\s/g, "-")}`);
    }
  };

  return (
    <PageLayout>
      {chaosBoxes.length > 0 ? (
        <>
          <CarouselWrapper>
            <BoxCarousel>
              <BoxDiv>
                {isLoadingBoxes ? (
                  <LoadingMessage>{localization.loading}</LoadingMessage>
                ) : (
                  <BoxInCarousel src={chaosBoxPicture} alt={localization.no_image} />
                )}
              </BoxDiv>
              <CarouselRow>
                <LikeButton
                  src={dislikeIcon}
                  onClick={() => {
                    handleLikeDislike(false);
                  }}
                  alt={localization.dislike}
                />
                <LikeButton
                  src={likeIcon}
                  onClick={() => {
                    handleLikeDislike(true);
                  }}
                  alt={localization.like}
                />
              </CarouselRow>
            </BoxCarousel>
            <BoxDetailsWrapper>
              <BoxNameWrapper>
                <BoxNamePrice>{selectedChaosBox?.name}</BoxNamePrice>
                <BoxNamePrice>{`$${selectedChaosBox?.value}`}</BoxNamePrice>
              </BoxNameWrapper>
              <BoxTag>{selectedChaosBox?.tags.toString()}</BoxTag>
              <BoxDescription>{selectedChaosBox?.description}</BoxDescription>
              <ActionButton onClick={openBoxAction}>
                {localization.open_box}
              </ActionButton>
            </BoxDetailsWrapper>
          </CarouselWrapper>
          <BoxItemsLabel>{localization.items_in_the_box}</BoxItemsLabel>
          <BoxItems box={selectedChaosBox} />
        </>
      ) : (
        <LoadingMessage>{localization.all_boxes_already_liked_disliked}</LoadingMessage>
      )}

      <FavLabel>{localization.favorite_boxes}</FavLabel>
      <FavouriteBoxes />
      <Footer />
    </PageLayout>
  );
};
export default ChaosPage;
