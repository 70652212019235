// src/components/BoxesPage.tsx
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { DynamicBoxesForAll } from "./DynamicBoxes";
import { BoxType } from "../utils/boxTypes";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import {
  fetchAllBoxes,
  setCurrentPage,
  setSearchString,
} from "../features/allBoxesSlice";
import debounce from "lodash/debounce";
import { FaSearch } from "react-icons/fa"; // Assuming you have a search icon component
import localization from "../services/localization";
import BoxesPageHeading from "./BoxesPageHeading";
import BackButton from "./BackButton";

const AllBoxesPageWrapper = styled.div`
  padding: 20px;
  color: #fff;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

// Wrapper Component for the entire row
const RowWrapper = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

// SortBy Component
const SortByContainer = styled.div`
  width: 150px;
  font-family: Verdana;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6b8a99;
`;

// Dropdown Container with Select
const DropdownContainer = styled.div`
  width: 220px;
  height: 24px;
  display: flex;
  align-items: center;
`;

const SelectDropdown = styled.select`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  border-radius: 8px;
  /* border: solid 1px #a1e0e5; */

  flex-grow: 0;
  font-family: Verdana;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
  background-color: transparent;
`;

// SearchBar Component
const SearchBarContainer = styled.div`
  width: 100%;
  margin: 0 0 0 8px;
  padding: 4px 8px 4px 0;
  border-radius: 8px;
  border: solid 1px #6b8a99;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px;
  width: 100%;
  background-color: transparent;
  color: white;
`;

const SearchIcon = styled.div`
  margin-left: 8px; // Adjust the margin as needed
  width: 16px;
  height: 16px;
  color: #6b8a99;
`;

const AllBoxesPage: React.FC = () => {
  const [boxCount, setBoxCount] = useState(2);
  const [selectedSort, setSelectedSort] = useState<BoxType>(BoxType.Name); // Default value is 'name'
  const contentRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setBoxCount(2);
      } else if (windowWidth < 768) {
        setBoxCount(3);
      } else if (windowWidth < 1024) {
        setBoxCount(4);
      } else {
        setBoxCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // If the user has stopped typing for 1000 milliseconds (adjust as needed),
      // trigger the API call using the current search text.
      // You can adjust the idle time based on your requirements.
      console.log("Idle time reached. Calling API with:", searchQuery);
      dispatch(setCurrentPage(1)); // Set currentPage to 1 on reset
      dispatch(setSearchString(searchQuery));
      dispatch(fetchAllBoxes( {boxType:selectedSort, isForGiftCategory: false}));
      // Call your API here with the current search text
      // Example: callApi(searchText);
    }, 1000); // Adjust the idle time as needed (e.g., 1000 milliseconds)
    return () => clearTimeout(timerId);
  }, [searchQuery]);

  const handleSearch = debounce((searchText) => {
    // Perform your search action using the searchText
    // You can make an API call or filter data based on the searchText
    // For now, let's just log the searchText
    console.log("Searching for:", searchText);
    dispatch(setCurrentPage(1)); // Set currentPage to 1 on reset
    dispatch(setSearchString(searchQuery));
    dispatch(fetchAllBoxes( {boxType:selectedSort, isForGiftCategory: false}));
  }, 1000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)

  const handleChange = (event: { target: { value: any } }) => {
    const newText = event.target.value;
    setSearchQuery(newText);
    //handleSearch(newText);
  };

  const updateSortValueAndCallApi = (value: BoxType) => {
    setSelectedSort(value);
    dispatch(setCurrentPage(1)); // Set currentPage to 1 on reset
    dispatch(fetchAllBoxes( {boxType:value, isForGiftCategory: false}));
  };

  return (
    <AllBoxesPageWrapper ref={contentRef}>
      <RowWrapper>
        <DropdownContainer>
          <SortByContainer>{localization.sortby}</SortByContainer>
          <SelectDropdown
            value={selectedSort}
            onChange={(e) =>
              updateSortValueAndCallApi(e.target.value as BoxType)
            }
          >
            <option value={BoxType.Name}>{localization.name}</option>
            <option value={BoxType.Featured}>{localization.featured}</option>
            <option value={BoxType.New}>{localization.new}</option>
            <option value={BoxType.Old}>{localization.old}</option>
            <option value={BoxType.Hot}>{localization.hot}</option>
          </SelectDropdown>
        </DropdownContainer>
        <SearchBarContainer>
          <SearchInput
            type="text"
            placeholder={localization.search_boxes_placeholder}
            value={searchQuery}
            onChange={handleChange}
          />
          <SearchIcon>
            <FaSearch />
          </SearchIcon>
          {/* Add search icon if needed */}
        </SearchBarContainer>
      </RowWrapper>
      <DynamicBoxesForAll
        boxCount={boxCount}
        boxType={selectedSort}
        page="boxes"
      />
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </AllBoxesPageWrapper>
  );
};

export default AllBoxesPage;

export const GiftCategoryBoxesPage: React.FC = () => {
  const [boxCount, setBoxCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setBoxCount(2);
      } else if (windowWidth < 768) {
        setBoxCount(3);
      } else if (windowWidth < 1024) {
        setBoxCount(4);
      } else {
        setBoxCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  const giftCategoryName = useSelector(
    (state: RootState) => state.allBoxes.giftCategoryName
  );

  return (
    <AllBoxesPageWrapper ref={contentRef}>
      <BackButton />
      <BoxesPageHeading $gradientColors={["#ffd400 0%", "#0af 100%"]}>
        {giftCategoryName}
      </BoxesPageHeading>
      <DynamicBoxesForAll
        boxCount={boxCount}
        boxType={BoxType.Name}
        page={`gifts/${giftCategoryName.replace(/\s/g, "-")}`}
      />
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </AllBoxesPageWrapper>
  );
};
