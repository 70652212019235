import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FaEye, FaEyeSlash, FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../features/userSlice';
import { useModal } from '../context/ModalContext';
import { RootState, AppDispatch } from '../app/store';
import { selectPage } from '../features/pageSlice';

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SectionTitle = styled.h2`
  color: #d9ffff;
  font-family: Verdana;
  margin-bottom: 24px;
`;

const FormGroup = styled.div`
  width: 100%;
  max-width: 472px;
  margin-bottom: 16px;
`;

const Label = styled.label`
  color: #d9ffff;
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const PasswordInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 40px 4px 4px;
  font-size: 14px;
  border: 1px solid #ccc;
`;

const TogglePasswordButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #2aa298;
`;

const ValidationList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  margin: 10px 0;
  list-style: none;
`;

const ValidationItem = styled.li<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? 'green' : 'red')};
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const ActionButton = styled.button`
  width: 100px;
  height: 34px;
  margin-top: 16px;
  font-family: Verdana;
  font-size: 14px;
  color: #a3becc;
  border: 1px solid #a3becc;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
  }
`;

const LoadingIndicator = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2aa298;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const ResetPasswordComponent: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();

    const resetPasswordSuccess = useSelector((state: RootState) => state.user.resetPasswordSuccess);
    const resetPasswordError = useSelector((state: RootState) => state.user.resetPasswordError);
    const resetPasswordLoading = useSelector((state: RootState) => state.user.resetPasswordLoading);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const validatePassword = (password: string) => ({
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        number: /[0-9]/.test(password),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });

    const validationResults = validatePassword(newPassword);

    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }

        if (!Object.values(validationResults).every(Boolean)) {
            toast.error("Password must meet all criteria.");
            return;
        }

        if (!token) {
            toast.error("Invalid or missing token.");
            return;
        }

        try {
            await dispatch(resetPassword({ token, newPassword })).unwrap();
            if (resetPasswordSuccess) {
                setNewPassword('');
                setConfirmPassword('');
                setTimeout(() => {
                    window.location.href = "/";
                    dispatch(selectPage("home"));
                    navigate(`/`);
                }, 2000);

            }
        } catch {
            if (resetPasswordError) {
                toast.error(resetPasswordError);
            }
        }
    };

    return (
        <Container>
            <SectionTitle>Reset your password</SectionTitle>
            <FormGroup>
                <Label>New Password:</Label>
                <InputWrapper>
                    <PasswordInput
                        type={showNewPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TogglePasswordButton onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </TogglePasswordButton>
                </InputWrapper>
                <ValidationList>
                    <ValidationItem isValid={validationResults.length}><FaCheck /> 8 Characters</ValidationItem>
                    <ValidationItem isValid={validationResults.uppercase}><FaCheck /> Uppercase</ValidationItem>
                    <ValidationItem isValid={validationResults.lowercase}><FaCheck /> Lowercase</ValidationItem>
                    <ValidationItem isValid={validationResults.number}><FaCheck /> Number</ValidationItem>
                    <ValidationItem isValid={validationResults.specialChar}><FaCheck /> Special Character</ValidationItem>
                </ValidationList>
            </FormGroup>
            <FormGroup>
                <Label>Confirm New Password:</Label>
                <InputWrapper>
                    <PasswordInput
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <TogglePasswordButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </TogglePasswordButton>
                </InputWrapper>
            </FormGroup>
            <ActionButton
                disabled={resetPasswordLoading}
                onClick={handleResetPassword}>
                {resetPasswordLoading ? <LoadingIndicator /> : 'Update'}
            </ActionButton>
        </Container>
    );
};

export default ResetPasswordComponent;
