// src/ProfileComponent.tsx

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { activateUser } from '../features/userSlice';
import { selectPage } from '../features/pageSlice';
import { ModalType, useModal } from '../context/ModalContext';

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
  color: #d9ffff;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const Button = styled.button`
  width: 100px;
  height: 40px;
  background-color: #2aa298;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1e8174;
  }

  &:disabled {
    background-color: #5fa89f;
    cursor: not-allowed;
  }
`;

const LoadingIndicator = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2aa298;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ActivateAccountComponent: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();
    const { closeModal, openModal } = useModal();

    const activationLoading = useSelector((state: RootState) => state.user.activationLoading);
    const activationSuccess = useSelector((state: RootState) => state.user.activationSuccess);
    const activationError = useSelector((state: RootState) => state.user.activationError);

    useEffect(() => {
        if (token) {
            dispatch(activateUser({ token }));
        } else {
           // toast.error("Invalid or missing token.");
        }
    }, [dispatch, token]);

    return (
        <Container>
            {activationLoading && <Title>Activating account ...</Title>}
            {activationSuccess && <Title>Your account activated successfully.</Title>}
            {activationError && <Title>{activationError}</Title>}

            <ButtonContainer>
                <Button onClick={() => {
                    openModal(ModalType.SIGN_IN);

                }}>Login</Button>
                <Button onClick={() => {
                    window.location.href = "/";
                    dispatch(selectPage("home"));
                    navigate(`/`);

                }}>Home</Button>
            </ButtonContainer>

            {activationLoading && <LoadingIndicator />}
        </Container>
    );
};

export default ActivateAccountComponent;
