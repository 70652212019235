import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoIosAddCircleOutline } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css';
import PurchaseOMGC from './PurchaseOMGC';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { getUserTransactions, Transaction } from '../features/userSlice';
import ReactPaginate from 'react-paginate';
import { formatCurrency } from '../utils/utility';

interface LocalTransaction {
  date: string;
  detail: string;
  amount: number;
  type: 'Credit' | 'Debit';
  balance: number;
  box_id: string;
  box_title: string;
  api_type: string;
}

// Conversion function
const convertToLocalTransaction = (apiTransaction: Transaction): LocalTransaction => {
  return {
    date: new Date(apiTransaction.created_at + 'Z').toLocaleString(),
    detail: apiTransaction.description,
    box_id: apiTransaction.box_id,
    box_title: apiTransaction.box_title,
    api_type: apiTransaction.type,
    amount: apiTransaction.credit - apiTransaction.debit,
    type: apiTransaction.credit > 0 ? 'Credit' : 'Debit',
    balance: apiTransaction.balance_after,
  };
};

const Container = styled.div`
  display: inline-block;
  background: transparent;
  margin-top: 1rem;
  width: 100%;
  border: 1px solid #004d4d;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000d0d;
  color: #d9ffff;
  height: 40px;
  padding-left: 1rem;

  span {
    font-weight: bold;
  }

  div {
    display: flex;
    align-items: center;

    span {
      font-weight: normal;
    }
  }
`;

const Select = styled.select`
  margin-left: 0.5rem;
`;

const Option = styled.option``;

const TransactionTable = styled.div`
  width: 100%;
  border: 1px solid #004d4d;
`;

const Row = styled.div`
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid #004d4d;

  &:last-child {
    border-bottom: none;
  }
`;

const DateCell = styled.div`
  flex-basis: 20%; /* Adjust percentage as needed for Date column */
  text-align: center;
`;

const HeadingDateCell = styled.div`
  color: #ccfffb;
  flex-basis: 20%; /* Adjust percentage as needed for Date column */
  text-align: center;
`;

const HeadingDetailCell = styled.div`
  flex-basis: 50%; /* Adjust percentage as needed for Detail column */
  text-align: center; /* Left-aligning to match the provided example */

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  color: #ccfffb;
`;

const DetailCell = styled.div`
  flex-basis: 50%; /* Adjust percentage as needed for Detail column */
  text-align: left; /* Left-aligning to match the provided example */

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  color: #ccfffb;
`;


const AmountCell = styled.div`
  flex-basis: 10%; /* Smaller space for Amount */
  text-align: center; /* Center the text horizontally within the cell */
  color: #ff4c4c; /* Assuming this style for the red text */
  display: flex; /* Use flexbox to align content */
  justify-content: flex-end; /* Aligns content to the right */
  padding-right: 25px; /* Optional: Add padding to ensure space on the left */
`;

const HeadingAmountCell = styled.div`
  color: #ccfffb;
  flex-basis: 10%; /* Adjust percentage as needed for Date column */
  text-align: center;
`;


const CreditDebitCell = styled.div`
  flex-basis: 10%; /* Smaller space for Credit/Debit */
  text-align: center;
  color: #ff4c4c; /* Assuming this style for the red text */
`;

const HeadingCreditDebitCell = styled.div`
  color: #ccfffb;
  flex-basis: 10%; /* Adjust percentage as needed for Date column */
  text-align: center;
`;

const BalanceCell = styled.div`
  flex-basis: 10%; /* Smaller space for Balance */
  text-align: center;
  color: #ccfffb; /* Example color */
`;

const HeadingBalanceCell = styled.div`
  color: #ccfffb;
  flex-basis: 10%; /* Adjust percentage as needed for Date column */
  text-align: center;
`;


const HeadingRow = styled(Row)`
  height: 24px;
  background-color: #004d4d;
  padding: 0 8px;
`;


const HeadBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #004d4d;
  height: 50px;
  padding-left: 1rem;
`;

const Title = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
`;

const TxTypeSelect = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AddText = styled.span`
  font-weight: normal;
  margin-right: 0.5rem;
  color: white;
`;

const AddIcon = styled(IoIosAddCircleOutline)`
  width: 40px;
  height: 40px;
  color: white;
  padding-right: 1rem;
`;

const Spacer = styled.div`
  height: 100px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #004d4d;
  color: white;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

const TransactionDetail = ({ transaction }: { transaction: LocalTransaction }) => {
  const baseUrl = window.location.origin;
  const boxLink = `${baseUrl}/boxes/${transaction.box_id}`;

  const detailContent = transaction.api_type.includes('box_opened') ? (
    <>
      The user opened the box named{' '}
      <a href={boxLink} target="_blank" rel="noopener noreferrer">
        {transaction.box_title}
      </a>
    </>
  ) : (
    transaction.detail
  );

  return (
    <DetailCell className={'normal'}>
      {detailContent}
    </DetailCell>
  );
};

const TransactionComponent: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const dispatch: AppDispatch = useDispatch();

  const transactions = useSelector((state: RootState) => state.user.transactions || []);
  const transactionsLoading = useSelector((state: RootState) => state.user.transactionsLoading);
  const transactionsError = useSelector((state: RootState) => state.user.transactionsError);
  const totalTransactions = useSelector((state: RootState) => state.user.totalTransactions);

  const offset = (currentPage - 1) * limit;

  useEffect(() => {
    dispatch(getUserTransactions({ offset, limit }));
  }, [dispatch, offset, limit]);

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const formattedTransactions: LocalTransaction[] = transactions.map(convertToLocalTransaction);

  return (
    <>
      <Container>
        <HeadBalance>
          <Title>Balance</Title>
          <TxTypeSelect onClick={() => setShowModal(true)}>
            <AddText>Add</AddText>
            <AddIcon />
          </TxTypeSelect>
        </HeadBalance>

        <TransactionTable>
          <Head>
            <span>Transactions</span>
            <div>
              <span>Currency&nbsp;</span>
              <Select id="tx-currency">
                <Option value="getBenePointsUserHistory">USDT</Option>
              </Select>
            </div>
          </Head>
          <HeadingRow>
            <HeadingDateCell>Date</HeadingDateCell>
            <HeadingDetailCell>Detail</HeadingDetailCell>
            <HeadingAmountCell>Amount</HeadingAmountCell>
            <HeadingCreditDebitCell>Credit/Debit</HeadingCreditDebitCell>
            <HeadingBalanceCell>Balance</HeadingBalanceCell>
          </HeadingRow>
          {transactionsLoading ? (
            <div>Loading...</div>
          ) : transactionsError ? (
            <div>Error loading transactions</div>
          ) : (
            formattedTransactions.map((transaction, index) => (
              <Row key={index}>
                <DateCell>{transaction.date}</DateCell>
                <TransactionDetail transaction={transaction} />
                <AmountCell className={transaction.type === 'Credit' ? 'credit' : 'debit'}>
                  {formatCurrency(transaction.amount)}
                </AmountCell>
                <CreditDebitCell className={transaction.type === 'Credit' ? 'credit' : 'debit'}>
                  {transaction.type}
                </CreditDebitCell>
                <BalanceCell>{formatCurrency(transaction.balance)}</BalanceCell>
              </Row>
            ))
          )}
        </TransactionTable>

        <PaginationWrapper>
          <ReactPaginate
            pageCount={Math.ceil(totalTransactions / limit)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={({ selected }) => handlePageChange(selected + 1)}
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </PaginationWrapper>

        <PurchaseOMGC show={showModal} handleClose={() => setShowModal(false)} />
      </Container>
      <Spacer />
    </>
  );
};

export default TransactionComponent;
