import React from 'react';
import { TiArrowBack } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import localization from '../services/localization';

const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 204, 0.5);
  background-color: transparent;
  cursor: pointer;
  border: 0;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
  }
`;

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    // Navigate back to the last page
    navigate(-1);
  };

  return (
    <StyledBackButton onClick={handleBackButtonClick} type="button">
      <TiArrowBack />
      {localization.back}
    </StyledBackButton>
  );
};

export default BackButton;
