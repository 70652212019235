// src/App.tsx
import React, { useEffect } from "react";
import Layout from "./components/Layout";
import { ItemDetailsProvider } from "../src/context/ItemDetailsContext";
import ItemDetails from "../src/components/ItemDetails";
import initializeApp from "./api/init";
import checkAuth from "./api/auth";
import { AppDispatch } from "./app/store";
import { useDispatch } from "react-redux";
import { fetchUserInformation } from "./features/userSlice";
import localization, { checkSelectedLanguage, getLanguageSubject } from "./services/localization";
import { interval, throttle } from "rxjs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalProvider, ModalType, useModal } from "./context/ModalContext";
import { SignUp } from "./components/signup";
import { SignIn } from "./components/signin";
import Modal from "./components/modal";
import { ForgotPassword } from "./components/ForgotPassword";

// Initializing different libraries
initializeApp();
// Check for login and initialize axios
checkAuth();


const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const [language, setLanguage] = React.useState<string>(checkSelectedLanguage());

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = localStorage.getItem("token");
        if (accessToken != null) {
          localStorage.setItem("token", accessToken);
          console.log("==================================================");
          console.log(accessToken);
          console.log("==================================================");
          dispatch(fetchUserInformation());
        }
      } catch (e) {
        if (e instanceof Error) {
          alert(e.message);
          console.log(e.message);
        } else {
          console.log("An unknown error occurred:", e);
        }
      }
    };
    getAccessToken();
  }, []);

  getLanguageSubject
    .pipe(throttle(() => interval(1000)))
    .subscribe({
      next: (lang) => {
        setLanguage(lang as string);
      }
    });

  return (
    <ModalProvider>

      <ItemDetailsProvider>
        <Root/>
        <ItemDetails />
        <ToastContainer />
      </ItemDetailsProvider>
    </ModalProvider>
  );
};

export default App;


const Root: React.FC = () => {
  const { currentModal, closeModal } = useModal();

  return (
    <>
      <div>
        <Layout />
      </div>
      <Modal show={currentModal !== null} onClose={closeModal}>
        {currentModal === ModalType.SIGN_UP && <SignUp />}
        {currentModal === ModalType.SIGN_IN && <SignIn />}
        {currentModal === ModalType.FORGOT_PASSWORD && <ForgotPassword />}
      </Modal>
    </>
  );
};