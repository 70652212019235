import React from "react";
import styled from "styled-components";
import { useItemDetails } from "../context/ItemDetailsContext";
import { IMAGE_BASE_URL, IMAGE_ENDPOINT } from "../api/apiConfig";
import noImageAvailable from "../../src/assets/no_image.png";
import localization from "../services/localization";
import { formatNumber } from "../utils/utility";

interface SlidingBarProps {
  $isOpen: boolean;
}

const SlidingBar = styled.div<SlidingBarProps>`
  position: fixed;
  top: 0;
  right: ${({ $isOpen }) => ($isOpen ? "0" : "-100%")};
  width: 100%;
  height: 100%;
  background-color: #ffffffff;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 99;

  @media only screen and (min-width: 768px) {
    width: 450px;
    right: ${({ $isOpen }) => ($isOpen ? "0" : "-450px")};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #111111;
  color: #ffffff;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
`;

const Body = styled.div`
  padding: 16px;
  background-color: #111111;
  color: #ffffff;

  width: 100%;
  height: 100%;

  flex: 1;
  /* Add your styling for the second part here */
  display: flex;
  flex-direction: column;
  padding: 15px;

  /* background-color: rgba(0, 170, 255, 0.05); */
`;

const Text = styled.div`
  font-family: Verdana;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6b8a99;
  margin: 15px;
`;

const Price = styled.div`
  font-family: Verdana;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff7cc;
  background-color: #80d5ff;
  padding: 2px 5px; /* 5px padding on both sides */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: inline-block; /* Adjust width based on content */
  white-space: nowrap; /* Prevents wrapping */
  text-align: center; /* Centers the text inside the box */
  margin: 15px;
  max-width: max-content; /* Sets the maximum width based on the content */
`;


// const ItemImage = styled.img<{ isHovered: boolean }>`

const ItemImage = styled(({ isHovered, ...props }: { isHovered: boolean } & React.HTMLProps<HTMLImageElement>) => (
  <img {...props} />
))<{ isHovered: boolean }>`
  width: 50%; /* Adjust width to account for margins */
  height: auto;
  border-radius: 13px;
  object-fit: contain;
  left: 50%;
  transform: translateX(0%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
  margin: 4px auto; /* Set auto for equal left and right margins */
`;

const ItemDetails: React.FC = () => {
  const { item, closeDetails } = useItemDetails();

  if (!item) {
    return null;
  }

  // Function to generate the image source URL
  const getImageSource = (pic: string | undefined): string => {
    if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
      return `${IMAGE_BASE_URL}${IMAGE_ENDPOINT}${pic}`;
    }
    return noImageAvailable;
  };

  return (
    <SlidingBar $isOpen={!!item}>
      <Header>
        <h2>{localization.item_details}</h2>
        <CloseButton onClick={closeDetails}>X</CloseButton>
      </Header>
      <Body>
        <ItemImage
          src={getImageSource(item.detail?.picture)}
          isHovered={true}
        />
        <Price>${formatNumber(item.detail?.price)}</Price>
        <Text>{item.detail?.name}</Text>
      </Body>
    </SlidingBar>
  );
};

export default ItemDetails;
