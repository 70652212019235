// ItemDetailsContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Item } from '../utils/boxTypes';



interface ItemDetailsContextProps {
  item: Item | null;
  openDetails: (item: Item) => void;
  closeDetails: () => void;
}

const ItemDetailsContext = createContext<ItemDetailsContextProps | undefined>(undefined);

interface ItemDetailsProviderProps {
  children: ReactNode;
}

export const ItemDetailsProvider: React.FC<ItemDetailsProviderProps> = ({ children }) => {
  const [item, setItem] = useState<Item | null>(null);

  const openDetails = (newItem: Item) => {
    setItem(newItem);
  };

  const closeDetails = () => {
    setItem(null);
  };

  return (
    <ItemDetailsContext.Provider value={{ item, openDetails, closeDetails }}>
      {children}
    </ItemDetailsContext.Provider>
  );
};

export const useItemDetails = () => {
  const context = useContext(ItemDetailsContext);
  if (!context) {
    throw new Error('useItemDetails must be used within an ItemDetailsProvider');
  }
  return context;
};
