import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BoxType, Box } from "../utils/boxTypes";
import { RootState } from "../app/store";
import axios from "axios";

// Define the type for queryParams
interface QueryParams {
  offset: number;
  limit: number;
  order: string;
  q?: string; // Making 'q' property optional
  cat?: string;
}

interface AllBoxesState {
  boxes: Box[];
  isLoading: boolean;
  isNeedToRefresh: boolean;
  currentPage: number;
  boxesPerPage: number;
  totalItems: number;
  searchString: string;
  giftCategoryName: string;
}

const initialState: AllBoxesState = {
  boxes: [],
  isLoading: false,
  isNeedToRefresh: false,
  currentPage: 1,
  boxesPerPage: 20,
  totalItems: 0,
  searchString: "",
  giftCategoryName: "",
};

interface FetchBoxesPayload {
  boxType: BoxType;
  isForGiftCategory: boolean;
}

// Thunk to fetch boxes from the API
export const fetchAllBoxes = createAsyncThunk(
  "boxes/fetchBoxes",
  async ({ boxType, isForGiftCategory }: FetchBoxesPayload, { getState }) => {
    const state = getState() as RootState;

    if (!state.allBoxes) {
      console.error("allBoxes state is not defined");
      return;
    }

    const { currentPage, boxesPerPage, searchString, giftCategoryName } =
      state.allBoxes;
    const offset = (currentPage - 1) * boxesPerPage;

    // Use the defined type
    const queryParams: QueryParams = {
      offset,
      limit: boxesPerPage,
      order: boxType,
    };

    if (isForGiftCategory) {
      if (giftCategoryName.length > 0) {
        if (giftCategoryName == "Bronze Boxes") {
          queryParams.cat = "1-500";
        } else if (giftCategoryName == "Silver Boxes") {
          queryParams.cat = "501-1000";
        } else if (giftCategoryName == "Gold Boxes") {
          queryParams.cat = "1001-2500";
        } else if (giftCategoryName == "Platinum Boxes") {
          queryParams.cat = "2501-5000";
        } else if (giftCategoryName == "Diamond Boxes") {
          queryParams.cat = "5001-10000";
        }
      }
    } else {
      if (searchString.trim().length > 3) {
        queryParams.q = searchString.trim();
      }
    }

    const response = await axios.get("/boxes", {
      params: queryParams,
    });

    return { boxType, boxes: response.data.list, total: response.data.total };
  }
);

const allBoxesSlice = createSlice({
  name: "boxes",
  initialState,
  reducers: {
    setBoxesPerPage: (state, action: PayloadAction<number>) => {
      state.boxesPerPage = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setGiftCategoryName: (state, action: PayloadAction<string>) => {
      state.giftCategoryName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllBoxes.fulfilled, (state, action) => {
      const { boxType, boxes, total } = action.payload || {
        boxType: BoxType,
        boxes: [],
        total: 0,
      };

      if (state.currentPage == 1) {
        state.boxes = boxes;
      } else if (boxes.length > 0) {
        state.boxes = [...state.boxes, ...boxes];
      }
      state.isLoading = false;
      state.totalItems = total;
    });

    builder.addCase(fetchAllBoxes.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAllBoxes.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setBoxesPerPage,
  setCurrentPage,
  setSearchString,
  setGiftCategoryName,
} = allBoxesSlice.actions;
export default allBoxesSlice.reducer;
