import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../app/store";
import {
  Routes, // instead of "Switch"
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import BoxesPage from "./BoxesPage"; // Import the BoxesPage component
import BoxDetails, { GifBoxDetails } from "./BoxDetails";
import AllBoxesPage, { GiftCategoryBoxesPage } from "./AllBoxes";
import localization from "../services/localization";
import GiftsPage from "./GiftsPage";
import ChaosPage from "./ChaosPage";
import ProfileDetailsLayoutComponent from "./ProfileDetailsLayoutComponent";
import { selectPage } from "../features/pageSlice";
import ResetPasswordComponent from "./RestPassword";
import ActivateAccountComponent from "./ActivateAccount";

const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #000c0d;
  color: #755c5c;
  overflow-y: auto;
`;

const ComingSoon = styled.div`
  font-family: Verdana;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto; /* Horizontally center the div */
  align-items: center;
`;

const ContentArea: React.FC = () => {
  const selectedPage = useSelector(
    (state: RootState) => state.page.selectedPage
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const TOKEN = localStorage.getItem("token");
    const path = location.pathname;

    // Handle reset-password route which doesn't require token
    if (path.startsWith("/reset-password")) {
      dispatch(selectPage("reset-password"));
      return;
    }

    if (path.startsWith("/activate-account")) {
      dispatch(selectPage("activate-account"));
      return;
    }

    if (!TOKEN && (path.startsWith("/chaos") || path.startsWith("/profile"))) {
      navigate("/"); // Redirect to home page if no token
      return;
    }

    if (selectedPage === '' || selectedPage === 'boxes') {
      if (path.startsWith("/gifts")) {
        dispatch(selectPage("gifts"));
      } else if (path.startsWith("/chaos")) {
        dispatch(selectPage("chaos"));
      } else if (path.startsWith("/boxes")) {
        dispatch(selectPage("boxes"));
      } else if (path.startsWith("/profile")) {
        dispatch(selectPage("profile"));
      } else {
        dispatch(selectPage("home"));
      }
    }
  }, [selectedPage, location.pathname, dispatch, navigate]);

  return (
    <ContentWrapper>
      {(selectedPage === "boxes" || selectedPage === "home") && (
        <Routes>
          <Route path="/" element={<BoxesPage />} />
          <Route path="/boxes/" element={<AllBoxesPage />} />
          <Route path="/boxes/:boxId" element={<BoxDetails />} />
        </Routes>
      )}
      {selectedPage === "chaos" && (
        <Routes>
          <Route path="/chaos/" element={<ChaosPage />} />
          <Route path="/chaos/:boxId" element={<BoxDetails />} />
        </Routes>
      )}
      {selectedPage === "gifts" && (
        <Routes>
          <Route path="/gifts/" element={<GiftsPage />} />
          <Route path="/gifts/:categoryId" element={<GiftCategoryBoxesPage />} />
          <Route path="/gifts/:categoryId/:boxId" element={<GifBoxDetails />} />
        </Routes>
      )}
      {selectedPage === "reset-password" && (
        <Routes>
          <Route path="/reset-password/:token" element={<ResetPasswordComponent />} />
        </Routes>
      )}
      {selectedPage === "activate-account" && (
        <Routes>
          <Route path="/activate-account/:token" element={<ActivateAccountComponent />} />
        </Routes>
      )}
      {selectedPage === "affiliates" && (
        <ComingSoon>{localization.affiliates_content_coming_soon}</ComingSoon>
      )}
      {selectedPage === "vips" && (
        <ComingSoon>{localization.vips_content_coming_soon}</ComingSoon>
      )}
      {selectedPage === "profile" && <ProfileDetailsLayoutComponent />}



      {/* Add cases for other pages */}
    </ContentWrapper>
  );
};

export default ContentArea;
