// QRCodeComponent.tsx
import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

interface QRCodeComponentProps {
  value: string;
}

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;

   height:150px;
   width: 150px;
`;

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ value }) => {
  return (
    <QRCodeWrapper>
      <QRCode value={value} />
    </QRCodeWrapper>
  );
};

export default QRCodeComponent;
