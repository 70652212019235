import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from "./config";

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();

  // Get Auth0 configuration
  const config = getConfig();
  const domain = config.domain;
  const clientId = config.clientId;
  const redirectUri = window.location.origin + "/login";

  const onRedirectCallback = (appState?: AppState) => {
    const delayInMilliseconds = 1000;
    const timeoutId = setTimeout(() => {
      // Redirect logic after a successful authentication
      window.location.href = "/";
      navigate(
        appState && appState.returnTo
          ? appState.returnTo
          : window.location.pathname
      );
    }, delayInMilliseconds);
    // Make sure to clear the timeout to avoid any unexpected behavior
    return () => clearTimeout(timeoutId);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        ...(config.audience ? { audience: config.audience } : null),
        scope: "read:current_user update:current_user_metadata",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
