import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for toasts
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { useNavigate } from "react-router-dom";
import { useModal } from '../context/ModalContext';
import { forgotPassword } from '../features/userSlice';

const ForgotPasswordWrapper = styled.div`
  background-color: #193E40;
  padding: 30px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;

  &:hover {
    color: #bbb;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: white;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  padding-right: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #23877E;
  }
`;

const ForgotPasswordButton = styled(Button)`
  background-color: #2AA298;

  &:hover {
    background-color: #23877E;
  }
`;

const LoadingIndicator = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #2AA298; /* Green */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

type ForgotPasswordProps = {};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ }) => {
    const [email, setEmail] = useState('');

    const dispatch: AppDispatch = useDispatch();
    const forgotPasswordSuccess = useSelector((state: RootState) => state.user.forgotPasswordSuccess);
    const forgotPasswordError = useSelector((state: RootState) => state.user.forgotPasswordError);
    const forgotPasswordLoading = useSelector((state: RootState) => state.user.forgotPasswordLoading);
    const { closeModal } = useModal();

    const validateEmail = (email: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const resetForm = () => {
        setEmail('');
    };

    const validateInputs = () => {
        if (!email.trim()) {
            toast.error("Email is required!");
            return false;
        }

        if (!validateEmail(email)) {
            toast.error("Please enter a valid email address.");
            return false;
        }

        return true;
    };

    const handleForgotPassword = async () => {
        if (validateInputs()) {
            try {
                await dispatch(forgotPassword({ email })).unwrap();
                if (forgotPasswordSuccess) {
                    //   toast.success("Sign-in successful!");
                    resetForm();
                    setTimeout(() => {
                        closeModal(); // Close modal after 3 seconds
                    }, 2000);
                }
            } catch (error) {
                if (forgotPasswordError) {
                    toast.error(forgotPasswordError);
                }
            }
        }
    };

    return (
        <ForgotPasswordWrapper>
            <CloseButton onClick={() => { resetForm(); closeModal(); }}>X</CloseButton>
            <Title>Forgot Your Password?</Title>
            <Form>
                <Input
                    type="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <ForgotPasswordButton
                    type="button"
                    onClick={handleForgotPassword}
                    disabled={forgotPasswordLoading} // Disable button if loading
                >
                    {forgotPasswordLoading ? <LoadingIndicator /> : 'Submit'}
                </ForgotPasswordButton>
            </Form>
        </ForgotPasswordWrapper>
    );
};
