import { ReplaySubject } from "rxjs";
import { Item } from "../utils/boxTypes";
// apiConfig.ts
const baseURL = "https://demo1-api.boxchaos.com/api";
export const IMAGE_BASE_URL = "https://demo1-api.boxchaos.com";
export const IMAGE_ENDPOINT = "/file/";
export const returnUrlAfterLogout ="https://demo1.boxchaos.com/";

export default baseURL

export const getWinningItemSubject = new ReplaySubject<Item>(1);