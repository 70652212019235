// src/components/BoxesPage.tsx
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import giftBoxes, { BoxType } from "../utils/boxTypes";
import BoxesPageHeading from "./BoxesPageHeading";
import Footer from "./Footer";
import localization from "../services/localization";
import { useNavigate } from "react-router-dom";
import { darken } from "polished";
import { setBoxDetails } from "../features/boxDetailsSlice";
import { AppDispatch } from "../app/store";
import { useDispatch } from "react-redux";
import {
    setGiftCategoryName,
  } from "../features/allBoxesSlice";
  
const GiftsPageWrapper = styled.div`
  padding: 20px;
  color: #fff;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

const SquareGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 28px;
  margin: 37px;
`;

interface SquareBoxProps {
  $baseColor: string;
}

// Darken color function using polished
const darkenColor = (color: string, amount: number) => darken(amount, color);

const generateGradient = (baseColor: string) => {
  const gradientStops = [];
  for (let i = 0; i <= 100; i += 4) {
    const color = i % 8 === 0 ? baseColor : darkenColor(baseColor, 0.1);
    gradientStops.push(`${color} ${i}%`);
  }
  return gradientStops.join(", ");
};

const SquareBox = styled.div<SquareBoxProps>`
  width: 100%;
  padding-top: 56%;
  height: 50px;
  position: relative;
  border-radius: 16px;
  font-family: SonsieOne;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-image: linear-gradient(
    109deg,
    ${(props) => generateGradient(props.$baseColor)}
  );
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: visible; /* Ensure content doesn't affect the box size */
`;

interface GiftImageBackgroundProps {
  gradientStartColor: string;
  gradientEndColor: string;
}

const GiftImageBackground = styled.div<GiftImageBackgroundProps>`
  width: 100%; /* Adjust as needed */
  height: 84%; /* Adjust as needed */

  border-radius: 15px;
  box-shadow: inset 5px 5px 10px 0 rgba(0, 0, 0, 0.5),
    inset -5px -5px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    ${(props) => props.gradientStartColor} 0%,
    ${(props) => props.gradientEndColor} 100%
  );
  margin: auto; /* Center horizontally */
  margin-top: 0.8%; /* Adjust top margin as needed */
`;

const BoxContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: contain;
  padding: 4px; /* Adjust padding as needed */
  box-sizing: border-box;
`;

const BoxTitle = styled.h3`
  position: absolute;
  mix-blend-mode: overlay;
  font-family: Verdana;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  position: flex;
  bottom: 3.7%;
  left: 7.1%;
  margin: 0; /* Remove default margin */
  font-size: clamp(24px, 3vw, 24px);
`;

const BoxPrice = styled.h3`
  position: absolute;
  mix-blend-mode: overlay;
  font-family: Verdana;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  position: flex;
  bottom: 3.7%;
  right: 7.7%;
  margin: 0; /* Remove default margin */
`;

const BoxImage = styled.img<{ $isHovered: boolean }>`
  width: 100%;

  height: 50%; /* Adjust as needed */
  object-fit: contain;
  position: absolute;
  top: ${(props) => (props.$isHovered ? "8%" : "14%")};
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
`;

interface BoxImageProps {
  $isHovered: boolean;
}

const baseColors = ["#99471f", "#b2b2b2", "#b28212", "#69bfb1", "#8d9ad9"];

// const colorTuples = [
//     ["#99471f", "#1a130d"],
//     ["#b2b2b2", "#ccc"],
//     ["#b28212", "#cc9b29"],
//     ["#69bfb1", "#77d9d9"],
//     ["#8d9ad9", "#a59df2"],
//   ];

const colorTuples = [
  ["#33261a", "#1a130d"],
  ["#333333", "#1a1a1a"],
  ["#332b1a", "#1a160d"],
  ["#1a3333", "#0d1a17"],
  ["#252433", "#12131a"],
];

const GiftsPage: React.FC = () => {
  const [boxCount, setBoxCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);

  const [hoveredBox, setHoveredBox] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch

  const handleBoxClick = (boxId: string, name: string) => {
    dispatch(setGiftCategoryName(name));
    navigate(`/gifts/${name.replace(/\s/g, "-")}`);
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1000) {
        setBoxCount(1);
      } else if (windowWidth < 1200) {
        setBoxCount(2);
      } else {
        setBoxCount(3);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  return (
    <GiftsPageWrapper ref={contentRef}>
      <BoxesPageHeading $gradientColors={["#ffd400 0%", "#0af 100%"]}>
        {localization.gifts}
      </BoxesPageHeading>
      <SquareGrid columns={boxCount}>
        {giftBoxes.map((box, index) => {
          const [gradientStartColor, gradientEndColor] =
            colorTuples[index % colorTuples.length];

          return (
            <SquareBox
              $baseColor={baseColors[index % baseColors.length]} // Use modulo to loop through colors
              key={index}
              onMouseEnter={() => setHoveredBox(box.id)}
              onMouseLeave={() => setHoveredBox(null)}
              onClick={() => handleBoxClick(box.id, box.name)}
            >
              <BoxContent>
                <GiftImageBackground
                  gradientStartColor={gradientStartColor}
                  gradientEndColor={gradientEndColor}
                >
                  <BoxImage
                    src={"/assets/gift-box.png"}
                    $isHovered={hoveredBox === box.id}
                  />

                  <BoxImage
                    src={box.picture}
                    $isHovered={hoveredBox === box.id}
                  />
                </GiftImageBackground>
                <BoxTitle>{box.name}</BoxTitle>
                <BoxPrice>${box.value}</BoxPrice>
              </BoxContent>
            </SquareBox>
          );
        })}
      </SquareGrid>
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </GiftsPageWrapper>
  );
};

export default GiftsPage;
