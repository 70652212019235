// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import pageReducer from '../features/pageSlice';
import boxesReducer from '../features/boxesSlice';
import boxDeatilsReducer from '../features/boxDetailsSlice';
import allBoxesReducer from '../features/allBoxesSlice';
import userReducer from "../features/userSlice";
import chaosBoxesReducer from "../features/chaosSlice";

export const store = configureStore({
  reducer: {
    page: pageReducer,
    boxes: boxesReducer,
    boxDetails: boxDeatilsReducer,
    allBoxes: allBoxesReducer,
    user: userReducer,
    chaosBoxes : chaosBoxesReducer 
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
