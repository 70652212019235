// utility.ts

import { toast } from "react-toastify";
import { IMAGE_BASE_URL, IMAGE_ENDPOINT } from "../api/apiConfig";

export const getImageSource = (pic: string | undefined): string => {
    if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
      return `${IMAGE_BASE_URL}${IMAGE_ENDPOINT}${pic}`;
    }
    return "/assets/default-box.png";
  };
  
  // Other utility functions can be added here if needed
  

  export const showToast = (message: string, options?: object) => {
    toast(message, options);
  };
  
  export function formatNumber(value?: number): string {
    if (value === undefined || value === null) {
      return '0'; // or return any default value or placeholder
    }
    return value % 1 === 0
      ? value.toString()
      : value.toFixed(2);
  }

  export const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };