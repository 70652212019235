// src/features/boxDetailsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { Box, Item } from "../utils/boxTypes";
import axios from "axios";
import { getBalance } from "../features/userSlice";

interface BoxDetailsState {
  selectedBox: Box | null;
  selectedBoxId: string | null;
  loading: boolean;
  isLoadingWinningItem: boolean;
  error: string | null | undefined;
  winningItem: Item | null;
}

const initialState: BoxDetailsState = {
  selectedBox: null,
  selectedBoxId: null,
  loading: false,
  isLoadingWinningItem: false,
  error: null,
  winningItem: null,
};

export const fetchBoxDetails = createAsyncThunk(
  "boxDetails/fetchBoxDetails",
  async (boxId: string, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const selectedBoxId = state.boxDetails.selectedBoxId;
      setBoxDetails(boxId);
      // Use the api module instead of axios directly
      const response = await axios.get(`/box?id=${boxId}`);
      return response.data;
    } catch (error:any) {
      // Use rejectWithValue to handle the rejection
      return rejectWithValue(error.message);
    }
  }
);


export const fetchItemForWinning = createAsyncThunk(
  "boxDetails/fetchItemForWinning",
  async (boxId: string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const selectedBoxId = state.boxDetails.selectedBox?.id;

      // Use the api module instead of axios directly
      const response = await axios.post(`/box/open?id=${boxId}`);
      await dispatch(getBalance()).unwrap();
      return response.data as Item;
    } catch (error:any) {
      // Use rejectWithValue to handle the rejection
      return rejectWithValue(error.message);
    }
  }
);


const boxDetailsSlice = createSlice({
  name: "boxDetails",
  initialState,
  reducers: {
    setBoxDetails: (state, action: PayloadAction<string | null>) => {
      state.selectedBoxId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoxDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBoxDetails.fulfilled, (state, action: PayloadAction<Box>) => {
        state.selectedBox = action.payload;
        state.loading = false;
      })
      .addCase(fetchBoxDetails.rejected, (state, action) => {
        // Use a type assertion to handle the type
        const castAction = action as PayloadAction<string | undefined>;
        state.loading = false;
        state.error = castAction.payload || "An error occurred while fetching box details.";
      });

      builder.addCase(fetchItemForWinning.pending, (state) => {
        state.isLoadingWinningItem = true;
        state.error = null;
      })
      .addCase(fetchItemForWinning.fulfilled, (state, action: PayloadAction<Item>) => {
        state.winningItem = action.payload;
        state.isLoadingWinningItem = false;
      })
      .addCase(fetchItemForWinning.rejected, (state, action) => {
        // Use a type assertion to handle the type
        const castAction = action as PayloadAction<string | undefined>;
        state.isLoadingWinningItem = false;
        state.error = castAction.payload || "An error occurred while fetching winning item for box.";
      });
  },
});

export default boxDetailsSlice.reducer;
export const selectSelectedBox = (state: RootState) => state.boxDetails.selectedBox;
export const selectSelectedBoxId = (state: RootState) => state.boxDetails.selectedBoxId;
export const selectLoading = (state: RootState) => state.boxDetails.loading;
export const selectError = (state: RootState) => state.boxDetails.error;
export const { setBoxDetails } = boxDetailsSlice.actions;
