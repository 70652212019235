import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for toasts
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { signin } from '../features/userSlice';
import { selectPage } from '../features/pageSlice';
import { useNavigate } from "react-router-dom";
import checkAuth from "../api/auth";
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import { ModalType, useModal } from '../context/ModalContext';

const SignInWrapper = styled.div`
  background-color: #193E40;
  padding: 30px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;

  &:hover {
    color: #bbb;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: white;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Input = styled.input`
  padding: 10px;
  padding-right: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 16px;
`;

const TogglePasswordButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #2AA298;
  font-size: 18px;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #23877E;
  }
`;

const SignInButton = styled(Button)`
  background-color: #2AA298;

  &:hover {
    background-color: #23877E;
  }
`;

const SignUpButton = styled(Button)`
  background-color: #ffffff;
  color: #193E40;
  border: 2px solid #2AA298;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Link = styled.a`
  color: #2AA298;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingIndicator = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #2AA298; /* Green */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const CaptchaWrapper = styled.div`
  margin-bottom: 15px;
`;

type SignInProps = {};

export const SignIn: React.FC<SignInProps> = ({ }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const dispatch: AppDispatch = useDispatch();
  const signinSuccess = useSelector((state: RootState) => state.user.signinSuccess);
  const signinError = useSelector((state: RootState) => state.user.signinError);
  const signinLoading = useSelector((state: RootState) => state.user.signinLoading);
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const { closeModal, openModal } = useModal();

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setCaptchaToken(null);
    setShowPassword(false);
  };

  const validateInputs = () => {
    if (!email.trim() || !password.trim()) {
      toast.error("Both email and password are required!");
      return false;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }

    return true;
  };

  const handleSignIn = async () => {
    if (validateInputs()) {
      // Handle sign in logic here
      console.log('Sign In:', { email, password });

      try {
        await dispatch(signin({ email, password })).unwrap();
        if (signinSuccess) {
          toast.success("Sign-in successful!");
        }
      } catch (error) {
        if (signinError) {
          toast.error(signinError);
        }
      }
    }
  };

  useEffect(() => {
    if (signinSuccess) {
      window.location.href = "/";
      dispatch(selectPage("home"));
      navigate(`/`);
      checkAuth();
      resetForm();
      closeModal(); // Close modal when sign-in is successful
    }
  }, [signinSuccess, dispatch, navigate, closeModal]);

  return (
    <SignInWrapper>
      <CloseButton onClick={() => { resetForm(); closeModal(); }}>X</CloseButton>
      <Title>Sign In</Title>
      <Form>
        <Input
          type="email"
          placeholder="Email / User ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputWrapper>
          <Input
            type={showPassword ? "text" : "password"} // Toggle between text and password
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TogglePasswordButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </TogglePasswordButton>
        </InputWrapper>

        <div style={{ marginBottom: '15px', color: 'white', textAlign: 'right' }}>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevents the default anchor behavior
              openModal(ModalType.FORGOT_PASSWORD); // Opens the Forgot Password modal
            }}
          >
            Forgot Password?
          </Link>
        </div>

        <CaptchaWrapper>
          <ReCAPTCHA
            sitekey="6LeIpiUqAAAAAKK5YvPiPfgwUHy5JKDPnVZ64sLV" // Replace with your reCAPTCHA site key
            onChange={(token) => setCaptchaToken(token)}
          />
        </CaptchaWrapper>

        <SignInButton
          type="button"
          onClick={handleSignIn}
          disabled={signinLoading} // Disable button if loading
        >
          {signinLoading ? <LoadingIndicator /> : 'Sign In'}
        </SignInButton>
        <SignUpButton type="button" onClick={() => {
          openModal(ModalType.SIGN_UP);

        }}>Sign Up</SignUpButton>
      </Form>
    </SignInWrapper>
  );
};
