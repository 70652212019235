import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaAngleRight } from "react-icons/fa6";

const HeroSection = styled.section`
  position: relative;
  overflow: hidden;
`;

const CarouselContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  /* overflow: hidden; */
`;

const Card = styled.div`
  min-width: 100%;
  overflow: hidden;
  flex: 0 0 100%;
`;

const CardImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%; // Added to prevent stretching
`;

const Arrow = styled.a`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #246d6d;
  font-size: 24px;
  text-decoration: none;
  z-index: 2; /* Ensure arrows are above the images */
`;


const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

const IndicatorsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

const IndicatorDot = styled.div<{ $active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? 'white' : 'gray')};
  margin: 0 5px;
`;

const HeroComponent: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards: string[] = [
    '/assets/slides/slide_1.jpg',
    '/assets/slides/slide_2.jpg',
    '/assets/slides/slide_3.jpg',
    '/assets/slides/slide_4.jpg',
  ];

  const totalCards = cards.length;
  const changeInterval = 7000; // 5 seconds
  const [intervalId, setIntervalId] = useState<number | undefined>(undefined);

  const handleLeftArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
    resetTimer();
  };

  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
    resetTimer();
  };

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    startTimer();
  };
  
  const startTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
    }, changeInterval) as any); // Assert as any
  };
  
  useEffect(() => {
    startTimer();

    return () => clearInterval(intervalId);
  }, [currentIndex, totalCards]);
  
  return (
    <HeroSection>
      <CarouselContainer style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {cards.map((image, index) => (
          <Card key={index}>
            <CardImage src={image} alt={`Card ${index + 1}`} />
          </Card>
        ))}
      </CarouselContainer>
      <LeftArrow onClick={handleLeftArrowClick}><FaChevronLeft /></LeftArrow>
      <RightArrow onClick={handleRightArrowClick}><FaAngleRight /></RightArrow>
      <IndicatorsContainer>
        {cards.map((_, index) => (
          <IndicatorDot key={index} $active={index === currentIndex} />
        ))}
      </IndicatorsContainer>
    </HeroSection>
  );
};

export default HeroComponent;
