import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import axios from "axios";
import { Box, Item } from "../utils/boxTypes";


// Define the type for queryParams
interface QueryParams {
  offset: number;
  limit: number;
}


interface ChaosState {
    chaosBoxesList: Box[];
    chaosBoxItemsList: Item[];
    isLoadingChaosBoxes : boolean ;
    isLoadingChaosItems : boolean ;
    likeDislikeMarked : boolean ;
    isLoadingFavouriteBoxes : boolean ;
    favouriteBoxesList: Box[];
    isNeedToRefresh: boolean;
    currentPage: number;
    boxesPerPage: number;
    totalItems: number;
  
  }

  const initialState: ChaosState = {
    chaosBoxesList : [],
    chaosBoxItemsList : [] ,
    isLoadingChaosBoxes : false ,
    isLoadingChaosItems : false ,
    likeDislikeMarked : false ,
    isLoadingFavouriteBoxes : false ,
    favouriteBoxesList : [],
    isNeedToRefresh: false,
    currentPage: 1,
    boxesPerPage: 20,
    totalItems: 0,
};

  interface IPostBoxLikeDislike {
    boxId: string;
    isLiked: boolean;
  }

  const chaosSlice = createSlice({
    name: "chaosBoxes",
    initialState,
    reducers: {
      setBoxesPerPage: (state, action: PayloadAction<number>) => {
        state.boxesPerPage = action.payload;
      },
      setCurrentPage: (state, action: PayloadAction<number>) => {
        state.currentPage = action.payload;
      },  

    },
    extraReducers: (builder) => {
      builder.addCase(fetchChaosBoxes.pending, (state) => {
        state.isLoadingChaosBoxes = true;
      })
      .addCase(fetchChaosBoxes.fulfilled, (state, action: PayloadAction<{ boxes: Box[] }>) => {
        const { boxes } = action.payload;
        state.chaosBoxesList = boxes;
        state.isLoadingChaosBoxes = false;
      });

      builder.addCase(fetchChaosBoxes.rejected, (state, action) => {
        state.isLoadingChaosBoxes = false;
      });


      builder.addCase(fetchChaosBoxItems.pending, (state) => {
        state.isLoadingChaosItems = true;
      })
      .addCase(fetchChaosBoxItems.fulfilled, (state, action: PayloadAction<{ items: Item[] }>) => {
        const { items } = action.payload;
        state.chaosBoxItemsList = items;
        state.isLoadingChaosItems = false;
      });


      builder.addCase(postBoxLikeDislike.fulfilled,(state, action) => { 
        const { res, boxId } = action.payload || {
          res: false,
          boxId: "",
        };
  
        if (res) {
          // Remove the box from chaosBoxesList based on boxId
          const updatedBoxesList = state.chaosBoxesList.filter(
            (box) => box.id !== boxId
          );
  
          state.chaosBoxesList = updatedBoxesList;
        }
        state.likeDislikeMarked = res;
        console.log(res);
        state.isLoadingChaosItems = false;
        state.isNeedToRefresh = true;
      });

      builder.addCase(postBoxLikeDislike.rejected, (state, action) => {
        state.isLoadingChaosItems = false;
      });


      builder.addCase(fetchFavouriteBoxes.fulfilled, (state, action) => {
        const { boxes, total } = action.payload || {
          boxes: [],
          total: 0,
        };
  
        if (state.currentPage == 1) {
          state.favouriteBoxesList = boxes;
        } else if (boxes.length > 0) {
          state.favouriteBoxesList = [...state.favouriteBoxesList, ...boxes];
        }
        state.isLoadingFavouriteBoxes = false;
        state.totalItems = total;
        state.isNeedToRefresh = false;
      });
  

      builder.addCase(fetchFavouriteBoxes.pending, (state) => {
        state.isLoadingFavouriteBoxes = true;
      })

      builder.addCase(fetchFavouriteBoxes.rejected, (state, action) => {
        state.isLoadingFavouriteBoxes = false;
      });
    },
  });

  
  export const fetchChaosBoxes = createAsyncThunk("chaosBoxes/fetchChaosBoxes", async ( ) => {
    const response = await axios.get(`/boxes/chaos?offset=0&limit=1000`);
    return { boxes: response.data.list }; 
  });

  export const fetchChaosBoxItems = createAsyncThunk("chaosBoxes/fetchChaosBoxItems", async ( boxId : string ) => {
    const response = await axios.get(`box?id=${boxId}`);
    return { items : response.data.items }; 
  });

  export const postBoxLikeDislike = createAsyncThunk("chaosBoxes/postBoxLikeDislike", async ( { boxId  , isLiked } : IPostBoxLikeDislike ) => {
    const response = await axios.post(`box/like?id=${boxId}&liked=${isLiked}`);
    if(response.status == 200){
        return { res : true , boxId:boxId}; 
    }else{
        return { res : false, boxId:boxId }; 
    }
    
  });

  export const fetchFavouriteBoxes = createAsyncThunk("chaosBoxes/fetchFavouriteBoxes", async ( _ ,{ getState } ) => {
    const state = getState() as RootState;

    if (!state.chaosBoxes) {
      console.error("chaosBoxes state is not defined");
      return;
    }

    const { currentPage, boxesPerPage, searchString, giftCategoryName } =
    state.allBoxes;
  const offset = (currentPage - 1) * boxesPerPage;

    // Use the defined type
    const queryParams: QueryParams = {
      offset,
      limit: boxesPerPage,
    };


    const response = await axios.get("boxes/fav", {
      params: queryParams,
    });

    console.log('favourite boxex length '+ response.data.list.length);

    return { boxes: response.data.list, total: response.data.total };
  });



  export const {
    setBoxesPerPage,
    setCurrentPage,
  } = chaosSlice.actions;
  export default chaosSlice.reducer;

  export const chaosBoxesList = (state: RootState) => state.chaosBoxes.chaosBoxesList;
  export const chaosBoxItemsList = (state: RootState) => state.chaosBoxes.chaosBoxItemsList;
  export const favouriteBoxesList = (state: RootState) => state.chaosBoxes.favouriteBoxesList;
  export const isLoadingChaosBoxes = (state: RootState) => state.chaosBoxes.isLoadingChaosBoxes;
  export const isLoadingChaosItems = (state: RootState) => state.chaosBoxes.isLoadingChaosItems;
  export const isLoadingFavouriteBoxes = (state: RootState) => state.chaosBoxes.isLoadingFavouriteBoxes;
  export const likeDislikeMarked = (state: RootState) => state.chaosBoxes.likeDislikeMarked;
  export const isNeedToRefreshFavorites = (state: RootState) => state.chaosBoxes.isNeedToRefresh;