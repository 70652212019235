// src/components/Sidebar.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Feed from "../utils/Feed";
import FeedStyledComponent from "./FeedStyledComponent";
import localization from "../services/localization";

const SidebarWrapper = styled.div`
  width: 224px;
  height: 100%; /* Make Sidebar take up 100% height of its parent */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background-color: #00171a;
  overflow-y: auto; /* Make the sidebar content scrollable */

  @media (max-width: 768px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

const SidebarContent = styled.div`
  p {
    font-size: 16px;
    color: #fff; /* Adjust text color based on your design */
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  flex-direction: column;
  gap: 16px;
`;

const generateRandomImage = (index: number) =>
  `/static/slots/slot${index}.webp`;

const generateRandomFeed = () => {
  const randomIndex = Math.floor(Math.random() * 16) + 1;
  const randomImage = generateRandomImage(randomIndex);
  return new Feed(randomImage, "/logo.png", "$0.01");
};

const Sidebar: React.FC = () => {
  const [randomFeeds, setRandomFeeds] = useState<Array<Feed>>([]);

  useEffect(() => {
    // Function to update random feeds every 10 seconds
    const updateRandomFeeds = () => {
      const newRandomFeeds = Array.from({ length: 10 }, (_, index) =>
        generateRandomFeed()
      );
      setRandomFeeds(newRandomFeeds);
      // alert("coming");
    };

    // Initial update
    updateRandomFeeds();

    // Set interval to update every 10 seconds
    const intervalId = setInterval(updateRandomFeeds, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <SidebarWrapper>
      <TextWrapper> {localization.live_feed}</TextWrapper>
      <SidebarContent>
        {randomFeeds.map((feed, index) => (
          <FeedStyledComponent key={`${feed.image1}-${index}`} feed={feed} />
        ))}
      </SidebarContent>
    </SidebarWrapper>
  );
};

export default Sidebar;
