import React, { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { AppDispatch, RootState } from "../app/store";
import { selectPage } from "../features/pageSlice";
import { useNavigate } from "react-router-dom";
import localization from "../services/localization";
import { IMAGE_BASE_URL, IMAGE_ENDPOINT } from "../api/apiConfig";
import { getBalance } from "../features/userSlice";
import { formatCurrency } from "../utils/utility";

interface MenuProps {
  $isVisible: boolean;
  $leftPosition: number;
}

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
    margin-left: 15px;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BalanceLabel = styled.p`
    font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
  margin: 0;
`;

const BalanceAmount = styled.p`
   font-family: Verdana;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
  margin: 0;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Dropdown = styled.div`
  position: relative;
  cursor: pointer;
`;

const AvatarLabel = styled.label`
  &:focus {
    outline: none;
  }
`;

const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Menu = styled.ul<MenuProps>`
  position: absolute;
  top: 130%;
  left: ${(props) => props.$leftPosition}px;
  display: ${(props) => (props.$isVisible ? "block" : "none")};
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #013a44;
  border-radius: 0.375rem;
  border-color: #409dcc;
  list-style: none;
  z-index: 1000;
  margin: 0;
  width: 200px;
  transform: translateX(-70%);
`;

const MenuItem = styled.li`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
`;

const MenuLink = styled.a`
  color: white;
  text-decoration: none;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  height: 0.0625rem;
  margin: 0;
  padding: 0;
  background-color: #409dcc;
`;

const LogoutLink = styled.a`
  color: white;
  cursor: pointer;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

interface UserHeaderButtonProps {
  clickAction: (page: string) => void;
}

const UserHeaderButton: FC<UserHeaderButtonProps> = ({ clickAction }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const userInfo = useSelector((state: RootState) => state.user.user);
  const balance = useSelector((state: RootState) => state.user.balance); // Replace with your balance selector
  const balanceLoading = useSelector(
    (state: RootState) => state.user.balanceLoading
  );
  const balanceError = useSelector((state: RootState) => state.user.error);
 
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBalance()); // Fetch the balance when the component mounts
  }, [dispatch]);


  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
    calculateLeftPosition();
  };

  const calculateLeftPosition = () => {
    if (dropdownRef.current) {
      const dropdownWidth = dropdownRef.current.clientWidth;
      const viewportWidth = window.innerWidth;
      const avatarLabelWidth = 40; // Assuming a constant width for the avatar label

      const newLeftPosition = Math.min(
        0,
        viewportWidth - dropdownWidth - avatarLabelWidth
      );

      setLeftPosition(newLeftPosition);
    }
  };

  const logoutUser = () => {
    localStorage.clear();
    window.location.href = "/";
    dispatch(selectPage("home"));
    navigate(`/`);
  };

  let pic = "https://d3dvavv1qailhf.cloudfront.net/avatars/Female_2_s.png";
  if (userInfo?.picture && userInfo.picture.trim().length > 1) {
    pic = `${IMAGE_BASE_URL}${IMAGE_ENDPOINT}${userInfo.picture}`;
  }



  return (
    <Navbar>
      <BalanceContainer>
        <BalanceLabel>OMGC</BalanceLabel>
        <BalanceAmount>
          {/* {balanceLoading ? "Loading..." : balanceError ? `Error: ${balanceError}` : balance as String} */}
          {formatCurrency(parseFloat(balance ?? "0"))}
        </BalanceAmount>
      </BalanceContainer>
      <ProfileContainer>
        <Dropdown onClick={toggleDropdown}>
          <AvatarLabel tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <AvatarImage src={pic} alt={localization.profile} />
            </div>
          </AvatarLabel>
          <Menu
            ref={dropdownRef}
            $isVisible={isDropdownVisible}
            $leftPosition={leftPosition}
          >
            <MenuItem>
              <MenuLink onClick={() => clickAction("profile")}>
                {localization.profile_settings}
              </MenuLink>
            </MenuItem>
            <Divider />
            <MenuItem>
              <LogoutLink onClick={logoutUser}>
                {localization.logout}
              </LogoutLink>
            </MenuItem>
          </Menu>
        </Dropdown>
      </ProfileContainer>
    </Navbar>
  );
};

export default UserHeaderButton;
