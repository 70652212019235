// src/components/FeedStyledComponent.tsx
import React from "react";
import styled from "styled-components";
import Feed from "../utils/Feed"

const FeedStyledWrapper = styled.div`
  width: 192px;
  height: 128px;
  overflow: hidden;
  flex-grow: 0;
  padding: 16px;
  border-radius: 16px;
  background-color: #000c0d;
  position: relative;
  display: flex;
  align-items: center;
`;

const OuterStyledComponent = styled.div`
  position: relative;
  height: 100%;
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    transform: translateX(-100%);
  }
`;

const InnerStyledComponent = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  width: 192px;
  height: 128px;

`;

const Image = styled.img`
  align-self: center;
  max-height: 80%;
  max-width: 80%;
  /* width: 192px; */
  

`;

const PriceTag = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 0.375rem;
  background-color: #fff;
  color: #000;
  background-image: linear-gradient(to right, #ffc701, #faff01);
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  transition: color 300ms;
`;

interface FeedStyledProps {
  feed: Feed;
}

const FeedStyledComponent: React.FC<FeedStyledProps> = ({ feed }) => {
  return (
    <FeedStyledWrapper>
      <OuterStyledComponent>
        <InnerStyledComponent>
          <Image src={feed.image1} alt="" />
        </InnerStyledComponent>
        <InnerStyledComponent
          style={{
            position: "absolute",
            top: "0",
            left: "100%",
            width: "100%",
          }}
        >
          <Image src={feed.image2} alt="" />
        </InnerStyledComponent>
      </OuterStyledComponent>
      <PriceTag>{feed.price}</PriceTag>
    </FeedStyledWrapper>
  );
};

export default FeedStyledComponent;
