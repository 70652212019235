import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import QRCodeComponent from './QRCodeComponent';
import { useSelector } from 'react-redux';
import { FaClipboard } from 'react-icons/fa'; // Import from react-icons/fa

const CustomModal = styled(Modal)`
  .modal-content {
    border: 2px solid green; /* Add green border */
  }

  .modal-header {
    background-color: #00171d;
    color: white;
    border-bottom: none; /* Optional: Remove bottom border */
  }

  .modal-header .btn-close {
    color: white; /* Set close button color to white */
    filter: invert(1);
  }


  .modal-header .modal-title {
    font-family: Verdana;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .modal-body {
    background-color: #00171d;
    color: white;
  }
`;

const OuterDiv = styled.div`
  padding: 10px;
  background-color: #00171d;
  border-radius: 8px;
  color: #fff;
`;

const WalletCrossIconDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WalletInnerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const CrossIconDiv = styled.div`
  cursor: pointer;
`;

const EmailPasswordMainDiv = styled.div`
  margin-top: 20px;
`;

const PasswordMainDiv = styled.div`
  margin-bottom: 20px;
  input {
    width:100%;
  }
`;

const AddressLabelDiv = styled.div`
color:#676e74;
font-family: Verdana;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  margin-bottom: 10px;
`;

const EmailAddressMainDiv = styled.div`
  margin-bottom: 20px;
`;

const BtcBalanceMainDiv = styled.div`
  display: flex;
`;

const BtcBalanceLeft = styled.div`
  margin-right: 20px;
`;

// const RadioItem = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 10px;
// `;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Increased margin for better spacing */
  padding: 10px; /* Added padding for better spacing */
  border-radius: 8px; /* Rounded corners for a modern look */
  border: 1px solid #ccc; /* Light border for definition */
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

  input[type="radio"] {
    margin-right: 12px; /* Space between radio button and label */
    width: 20px; /* Increased size for radio button */
    height: 20px; /* Increased size for radio button */
    cursor: pointer; /* Change cursor to pointer */
    accent-color: #36ff00; /* Custom color for the radio button */
  }

  label {
    font-size: 16px; /* Larger font size for better readability */
    color: #333; /* Darker text color for contrast */
    cursor: pointer; /* Change cursor to pointer */
    user-select: none; /* Prevent text selection on click */
  }

  &:hover {
    background-color: #eaeaea; /* Light hover effect */
  }
`;

const AmountToBeDeposited = styled.p`
  font-size: 24px;
  font-family: Verdana;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

const TwoFactorMainDiv = styled.div`
  display: flex; /* Add this to enable flexbox */
  justify-content: center; /* Horizontally center the items */

  text-align: center;
  margin-bottom: 20px;
`;

const NoticeMainDiv = styled.div`
  margin-bottom: 20px;
  p {
    background-color: #676e74;
    padding: 10px;
    border-radius: 5px;
  }
`;

const SigninSignupMainDiv = styled.div`
  text-align: center;
`;

const SigninFirstDiv = styled.div`
  background-color: #36ff00;
  padding: 10px 20px;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
  display: inline-block;

  font-family: Verdana;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ccfffb;



  box-shadow: inset 4px 8px 12px 0 rgba(255, 255, 255, 0.5),
    inset -2px -6px 10px 0 rgba(0, 0, 0, 0.6);
  background-color: #00bbcc;
`;

// const StyledInput = styled.input`
//   width: 100%; /* Full width to fit container */
//   padding: 12px 16px; /* Generous padding for comfort */
//   font-size: 16px; /* Larger font size for readability */
//   border: 2px solid #ccc; /* Light border color */
//   border-radius: 8px; /* Rounded corners for a modern look */
//   background-color: #f9f9f9; /* Light background color */
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
//   transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions */

//   &:focus {
//     border-color: #36ff00; /* Highlight border color on focus */
//     outline: none; /* Remove default outline */
//     box-shadow: 0 0 4px rgba(54, 255, 0, 0.4); /* Shadow effect on focus */
//   }

//   /* Optional: Add styles for invalid state */
//   &:invalid {
//     border-color: #e74c3c; /* Red border for invalid input */
//     box-shadow: 0 0 4px rgba(231, 76, 60, 0.4); /* Shadow effect for invalid input */
//   }
// `;

// Define the prop types for CopyableInput
interface CopyableInputProps {
  depositAddress: string;
}

// Styled input container
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background: #fff;
`;

// Styled input element
const StyledInput = styled.input`
  border: none;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  font-size: 16px;
  color: #333;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #36ff00; // Add a focus effect
  }
`;

// Styled button for copying
const CopyButton = styled.button`
  background-color: #36ff00;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2dff00; // Darker shade for hover effect
  }

  svg {
    margin-right: 5px;
  }
`;

// CopyableInput Component
const CopyableInput: React.FC<CopyableInputProps> = ({ depositAddress }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(depositAddress);
    alert('Address copied to clipboard!');
  };

  return (
    <InputContainer>
      <StyledInput type="text" value={depositAddress} readOnly />
      <CopyButton onClick={copyToClipboard}>
        <FaClipboard size={16} />
      </CopyButton>
    </InputContainer>
  );
};


interface PurchaseOMGCProps {
  show: boolean;
  handleClose: () => void;
}

const PurchaseOMGC: React.FC<PurchaseOMGCProps> = ({ show, handleClose }) => {
  const wallets = useSelector((state: any) => state.user.wallets);
  const [selectedCurrency, setSelectedCurrency] = useState<'ethereum' | 'tron'>('tron');
  const [amount, setAmount] = useState<number>(50000); // Default value

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCurrency(event.target.value as 'ethereum' | 'tron');
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(event.target.value));
  };

  const depositAddress = wallets[selectedCurrency];
  const currencyLabel = selectedCurrency === 'tron' ? 'USDT TRC-20' : 'USDT ERC-20';
  const amountToBeDeposited = amount / 1000;

  return (
    <CustomModal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>PURCHASE OMGC</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OuterDiv>
          <EmailPasswordMainDiv>
            <PasswordMainDiv>
              <AddressLabelDiv>
                <p>
                  OMGC{' '}
                  <span style={{ color: '#36ff00' }}>
                    &nbsp;( 1 USDT = 1000 OMGC )
                  </span>
                </p>
              </AddressLabelDiv>
              <StyledInput
                type="number"
                value={amount}
                onChange={handleAmountChange}
              />
            </PasswordMainDiv>
            <EmailAddressMainDiv>
              <AddressLabelDiv>
                <p>Select Currency</p>
              </AddressLabelDiv>
              <BtcBalanceMainDiv>
                {wallets.ethereum && (
                  <BtcBalanceLeft>
                    <RadioItem>
                      <input
                        type="radio"
                        id="radio1"
                        name="currency"
                        value="ethereum"
                        checked={selectedCurrency === 'ethereum'}
                        onChange={handleCurrencyChange}
                      />
                      <label htmlFor="radio1">USDT ERC-20</label>
                    </RadioItem>
                  </BtcBalanceLeft>
                )}
                {wallets.tron && (
                  <BtcBalanceLeft>
                    <RadioItem>
                      <input
                        type="radio"
                        id="radio2"
                        name="currency"
                        value="tron"
                        checked={selectedCurrency === 'tron'}
                        onChange={handleCurrencyChange}
                      />
                      <label htmlFor="radio2">USDT TRC-20</label>
                    </RadioItem>
                  </BtcBalanceLeft>
                )}
              </BtcBalanceMainDiv>
            </EmailAddressMainDiv>
            <EmailAddressMainDiv>
              <AddressLabelDiv>
                <p>Amount (USDT)</p>
              </AddressLabelDiv>
              <AmountToBeDeposited>
                {amountToBeDeposited} <span style={{ fontSize: '20px', color: '#36ff00' }}>{currencyLabel}</span>
              </AmountToBeDeposited>
            </EmailAddressMainDiv>
            <PasswordMainDiv>
              <AddressLabelDiv>
                <p>
                  Deposit Address{' '}
                  <span style={{ color: '#36ff00' }}>
                    ( Note: {currencyLabel} )
                  </span>
                </p>
              </AddressLabelDiv>
              <CopyableInput depositAddress={depositAddress} />
              </PasswordMainDiv>
            <TwoFactorMainDiv>
              <QRCodeComponent value={depositAddress} />
            </TwoFactorMainDiv>
            <NoticeMainDiv>
              <p>
                <b>NOTICE: &nbsp;</b> Please Send only {currencyLabel} to this
                address. Then click the button below to confirm the
                transaction.
              </p>
            </NoticeMainDiv>
            <SigninSignupMainDiv>
              <SigninFirstDiv onClick={handleClose}>
                <p>Confirm Transaction</p>
              </SigninFirstDiv>
            </SigninSignupMainDiv>
          </EmailPasswordMainDiv>
        </OuterDiv>
      </Modal.Body>
    </CustomModal>
  );
};

export default PurchaseOMGC;