// src/features/boxesSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BoxType, Box } from "../utils/boxTypes";
import { RootState } from "../app/store";
import axios from "axios";

interface BoxesState {
  boxes: Box[];
  featuredBoxes: Box[];
  newBoxes: Box[];
  hotBoxes: Box[];
  limit: number; // Add a limit property to the state
}

const initialState: BoxesState = {
  boxes: [],
  featuredBoxes: [],
  newBoxes: [],
  hotBoxes: [],
  limit: 20, // Set an initial limit
};

// Thunk to fetch boxes from the API
export const fetchBoxes = createAsyncThunk("boxes/fetchBoxes", async (boxType: BoxType, { getState }) => {
  const state = getState() as RootState;
  const limit = state.boxes.limit; // Get the limit from the state
  const response = await axios.get(`/boxes?offset=0&limit=${limit}&order=${boxType}&featured=${boxType===BoxType.Featured}`);
  return { boxType, boxes: response.data.list }; // Pass boxType along with the boxes
});

const boxesSlice = createSlice({
  name: "boxes",
  initialState,
  reducers: {
    // ... other reducers
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload; // Update the limit in the state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBoxes.fulfilled, (state, action: PayloadAction<{ boxType: BoxType; boxes: Box[] }>) => {
      const { boxType, boxes } = action.payload;
      switch (boxType) {
        case BoxType.Featured:
          state.featuredBoxes = boxes;
          break;
        case BoxType.New:
          state.newBoxes = boxes;
          break;
        case BoxType.Hot:
          state.hotBoxes = boxes;
          break;
        default:
          state.boxes = boxes;
      }
    });
  },
});

export const { setLimit } = boxesSlice.actions;
export default boxesSlice.reducer;
