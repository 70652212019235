// src/components/LanguageSelector.tsx

import React, { useEffect, useState } from "react";
import localization, {
  getLanguageSubject,
  setLocalizationLanguage,
} from "../services/localization";
import ReactFlagsSelect from "react-flags-select";
import { UUIDStorage } from "../services/storage";
import { styled } from "styled-components";

const StyledFlagsSelect = styled(ReactFlagsSelect)`
  /* Add your custom styles here */
  color: #070707 !important;
  margin-top: 5px;

  @media (max-width: 600px) {
    font-size: 5px;
  }
`;

const mapLanguageCodeToCountryCode = (languageCode: string): string => {
  const codeMapping: Record<string, string> = {
    en: "US",
    ur: "PK",
    cn: "CN",
    // Add more mappings as needed
  };

  return codeMapping[languageCode] || languageCode;
};

const LanguageSelector: React.FC = () => {
  const storage = UUIDStorage.getInstance();

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    mapLanguageCodeToCountryCode(storage.getLanguage())
  );

  const handleChangeLanguage = (countryCode: string) => {
    const languageMapping: Record<string, string> = {
      US: "en",
      PK: "ur",
      CN: "cn",
    };

    setSelectedLanguage(countryCode);
    const countryLanguage = languageMapping[countryCode] || "en";
    setLocalizationLanguage(countryLanguage);
  };

  return (
    <div>
      <StyledFlagsSelect
        id="languageSelector"
        countries={["US", "PK", "CN"]}
        customLabels={{ US: "en", PK: "ur", CN: "cn" }}
        selected={selectedLanguage}
        showSelectedLabel={false}
        onSelect={(countryCode) => handleChangeLanguage(countryCode)}
      />
    </div>
  );
};

export default LanguageSelector;
